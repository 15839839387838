import type { ElementType, SVGProps } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { scale040, scale060, spaceS, spaceXS } from '@xing-com/tokens';

type Props = {
  /**
   * The color of the sparkles. Colors from `@xing-com/tokens` can be used.
   * @default 'white'
   */
  color?: string;
  /**
   * Whether to use the short animation or the long one. Key differences
   * between long and short animation:
   * - Short lasts 5s. Delay of small sparkle is 0.2s (no delay for the big one).
   * - Long lasts 10s. Delay of small sparkle is 2.2s, 2s for the big one.
   * - Random lasts 20s. Delay of small sparkle is 0.2s (no delay for the big one).
   * Each have different keyframes stops configuration but same scale values.
   */
  variant?: 'long' | 'short' | 'random';
  sparklesAs?: ElementType;
};

export const AnimatedSparkles: React.FC<Props> = ({
  color,
  variant = 'long',
  sparklesAs = 'div',
}) => (
  <Sparkles as={sparklesAs} $color={color} $variant={variant}>
    <BigSparkle />
    <SmallSparkle />
  </Sparkles>
);

const sparklesLong = keyframes`
  0% {
    transform: scale(1);
  }
  2% {
    transform: scale(0.2);
  }
  4% {
    transform: scale(1.2);
  }
  8% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;
const sparklesShort = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;
const sparklesRandom = keyframes`
	8%,
	50%,
	60%,
	90%,
	93% {
		transform: scale(1);
	}

	10%,
	52%,
	62%,
	92%,
	95% {
		transform: scale(0.2);
	}

	12%,
	54%,
	64%,
	94%,
	97% {
		transform: scale(1.2);
	}

	14%,
	56%,
	66%,
	96%,
	99% {
		transform: scale(1);
	}
`;

const variants = {
  long: css`
    animation-name: ${sparklesLong};
    animation-duration: 10s;
    animation-delay: calc(var(--delay) - 2s);
  `,
  short: css`
    animation-name: ${sparklesShort};
    animation-duration: 2s;
    animation-delay: var(--delay);
  `,
  random: css`
    animation-name: ${sparklesRandom};
    animation-duration: 20s;
    animation-delay: calc(var(--delay) - 2s);
  `,
};

type SProps = {
  [K in keyof Omit<Props, 'sparklesAs'> as `$${K}`]: Props[K];
};
const Sparkles = styled.div<SProps>`
  display: flex;
  padding: 1px;

  & svg {
    fill: ${({ $color }) => $color ?? 'white'};
    ${({ $variant }) => $variant && variants[$variant]};
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
`;

// We need to render each Sparkle as their own svg as otherwise it is not
// possible to animate each sparkle with their specific transform-origin, as
// they use the transform origin from the svg parent
const Sparkle: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    role="presentation"
    {...props}
  >
    <path d="M8 16L6.15456 11.4233C5.86851 10.7128 5.2872 10.1315 4.5767 9.84544L0 8L4.5767 6.15456C5.2872 5.86851 5.86851 5.2872 6.15456 4.5767L8 0L9.84544 4.5767C10.1315 5.2872 10.7128 5.86851 11.4233 6.15456L16 8L11.4233 9.84544C10.7128 10.1315 10.1315 10.7128 9.84544 11.4233L8 16Z" />
  </svg>
);

const BigSparkle = styled(Sparkle)`
  width: ${scale060};
  height: ${scale060};
  --delay: 2s;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

const SmallSparkle = styled(Sparkle)`
  width: ${scale040};
  height: ${scale040};
  margin-left: -${spaceXS};
  margin-top: ${spaceS};
  --delay: 2.2s;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;
